body {
  padding-top: 50px;
  padding-bottom: 20px;
  color: #44E260;
  background-color: #222222;
  font-family: Papyrus, fantasy,"Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists 
 will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
  white-space: normal;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
  max-width: 280px;
}
.jumbotron {
padding-top: 30px;
padding-bottom: 30px;
margin-bottom: 30px;
color: inherit;
background-color: #296d29;
}